export const dataTableAllowedViewFields = {
    ActionButtonView: () => import("./ActionButtonView"),
    AggregateFunctionView: () => import("./AggregateFunctionView"),
    AuthorizedSignatureView: () => import("./AuthorizedSignatureView"),
    AutoIncrementView: () => import("./AutoIncrementView"),
    CheckBoxGroupView: () => import("./CheckBoxGroupView"),
    CheckboxView: () => import("./CheckboxView"),
    CurrencyView: () => import("./CurrencyView"),
    CurrencyTypesView: () => import("./CurrencyTypesView"),
    DIVView: () => import("./DIVView"),
    IconView: () => import("./IconView"),
    DateTimeRangeView: () => import("./DateTimeRangeView"),
    DateRangeView: () => import("./DateRangeView"),
    DateTimeView: () => import("./DateTimeView"),
    DateView: () => import("./DateView"),
    ESignatureView: () => import("./ESignatureView"),
    EntityVariableView: () => import("./EntityVariableView"),
    EntityView: () => import("./EntityView"),
    FileView: () => import("./FileView"),
    FormulaView: () => import("./FormulaView"),
    GlobalVariableView: () => import("./GlobalVariableView"),
    HorizontalLineView: () => import("./HorizontalLineView"),
    HtmlView: () => import("./HtmlView"),
    ImageView: () => import("./ImageView"),
    ListView: () => import("./ListView"),
    MultiLineTextView: () => import("./MultiLineTextView"),
    MultiSelectView: () => import("./MultiSelectView"),
    NumberView: () => import("./NumberView"),
    ParagraphView: () => import("./ParagraphView"),
    PhoneCountryCodeView: () => import("./PhoneCountryCodeView"),
    RadioView: () => import("./RadioView"),
    RadioButtonGroupView: () => import("./RadioButtonGroupView"),
    SelectView: () => import("./SelectView"),
    SingleLineContentView: () => import("./SingleLineContentView"),
    SingleLineTextView: () => import("./SingleLineTextView"),
    TimeView: () => import("./TimeView"),
    VideoView: () => import("./VideoView"),
    YesOrNoView: () => import("./YesOrNoView"),
    WeekDaysView: () => import("./WeekDaysView"),
    TimeRangeView: () => import("./TimeRangeView"),
    StarRatingView: () => import("./StarRatingView"),
    ConcatenateView: () =>import("./ConcatenateView"),
    PaymentView:() =>import("./PaymentView.vue"),
    IntegrationView:() => import("./IntegrationView"),
    IntegrationVariableView:() => import("./IntegrationVariableView"),
}

export default {
    ...dataTableAllowedViewFields
}